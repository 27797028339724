import {
	AppBar,
	Avatar,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	Menu,
	MenuItem,
	Toolbar,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ListItemText from '@mui/material/ListItemText';
import { Outlet, useNavigate } from 'react-router-dom';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import styled from '@emotion/styled';
import { getUserAvatar } from '~/helpers/common';
import { useAuth } from '~/hooks/auth';
import { useDarkMode } from '~/utils/contexts';
import useDidUpdateEffect from '~/hooks/useDidUpdateEffect';

const DRAWER_WIDTH = 240;
export default function Layout() {
	const [mobileOpen, setMobileOpen] = useState<boolean>(false);

	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	const userMenuOpen = Boolean(anchorElUser);
	const handleCloseUserMenu = (url?: string) => {
		setAnchorElUser(null);
		if (url) navigate(url);
	};
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const navigate = useNavigate();

	const { user } = useAuth();

	const navItems: { name: string; icon: IconName; route: string }[] = [
		{ name: 'overview', icon: 'square-kanban', route: '/' },
		{ name: 'users', icon: 'users', route: '/users' },
		{ name: 'gallagher users', icon: 'user-group', route: '/gallagher-users' },
		{ name: 'policies', icon: 'file', route: '/policies' },
		{ name: 'categories', icon: 'grid', route: '/categories' },
		{ name: 'Referring domains', icon: 'arrows-to-circle', route: '/referring-domains' },
	];

	const { darkMode, onToggle } = useDarkMode();

	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));

	useDidUpdateEffect(() => {
		setMobileOpen(false);
	}, [location.pathname]);

	// useEffect(() => {
	// 	return () => console.log('unmount Layout');
	// }, []);

	return (
		<Styles>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					width: '100%',
					// maxWidth: '300px',
					// ml: { sm: `${DRAWER_WIDTH}px` },
				}}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={() => setMobileOpen(true)}
						sx={{ mr: 2, display: { sm: 'none' } }}
					>
						<MenuIcon />
					</IconButton>
					<Tooltip title="Open settings">
						<IconButton onClick={handleOpenUserMenu} className="avatar">
							<Avatar src={getUserAvatar(user)} />
						</IconButton>
					</Tooltip>
					{userMenuOpen && (
						<Menu
							sx={{ mt: '45px' }}
							id="menu-appbar"
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={userMenuOpen}
							onClick={() => handleCloseUserMenu()}
							onClose={() => handleCloseUserMenu()}
						>
							<MenuItem onClick={() => handleCloseUserMenu('/profile')}>
								<Typography textAlign="center">Profile</Typography>
							</MenuItem>
							<MenuItem onClick={() => handleCloseUserMenu('/logout')}>
								<Typography textAlign="center">Logout</Typography>
							</MenuItem>
						</Menu>
					)}
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				sx={{ width: { xs: 0, sm: DRAWER_WIDTH }, flexShrink: { xs: 0 } }}
				aria-label="mailbox folders"
			>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					// container={container}
					disablePortal
					variant={mobile ? 'temporary' : 'permanent'}
					open={mobileOpen}
					onClose={() => setMobileOpen(false)}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
					}}
				>
					<div className="drawer">
						<Toolbar disableGutters>
							<img
								onClick={() => navigate('/')}
								className="logo"
								src={
									darkMode
										? 'https://arma-karma.s3.eu-west-2.amazonaws.com/assets/karmadillo-light.svg'
										: 'https://arma-karma.s3.eu-west-2.amazonaws.com/assets/greenLogo.svg'
								}
								alt="logo"
							/>
						</Toolbar>

						<Divider />
						<List className="drawer-list">
							{navItems.map((item, index) => (
								<ListItem key={item.name} disablePadding>
									<ListItemButton
										onClick={() => {
											navigate(item.route);
											// handleCloseUserMenu(item?.route);
										}}
										className={`${
											index === 0 && location.pathname === '/'
												? 'active'
												: location.pathname === item.route
												? 'active'
												: 'inactive'
										}`}
									>
										<FontAwesomeIcon
											className="drawer-item-icon"
											icon={['fas', item.icon]}
										/>
										<ListItemText
											className="list-item-text"
											primary={item.name}
										/>
									</ListItemButton>
								</ListItem>
							))}
							<ListItem disablePadding className="dark-toggle">
								<ListItemButton onClick={onToggle} className={'dark-toggle'}>
									<FontAwesomeIcon
										className="drawer-item-icon"
										icon={['fas', `${darkMode ? 'sun' : 'moon'}`]}
									/>
									<ListItemText
										classes={{ primary: 'list-item-text-primary' }}
										className="list-item-text"
										primary={`Enable ${darkMode ? 'light' : 'dark'} mode`}
									/>
								</ListItemButton>
							</ListItem>
						</List>
					</div>
				</Drawer>
			</Box>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					px: { xs: 1, sm: 2, md: 3 },
					py: 3,
					mt: 8,
					width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
					height: '100%',
					minHeight: 'calc(100vh - 64px)',
				}}
			>
				<Outlet />
			</Box>
		</Styles>
	);
}

const Styles = styled.div`
	display: flex;

	.main {
	}

	.drawer {
		height: 100%;
		max-height: 100vh;
		overflow: hidden;
		&-list {
			height: 100%;
		}
	}

	.dark-toggle {
		position: absolute;
		bottom: 40px;
		.list-item-text-primary {
			font-weight: 300;
		}
	}

	.avatar {
		border: 2px solid white;
		width: 32px;
		height: 32px;
		cursor: pointer;
		margin-left: auto;
	}

	.logo {
		cursor: pointer;
		max-width: ${({ theme }) => theme.spacing(8)};
		padding: ${({ theme }) => theme.spacing(1)};
		margin-left: ${({ theme }) => theme.spacing(1)};
	}

	.drawer-item-icon {
		padding-right: ${({ theme }) => theme.spacing(4)};
		width: 30px;

		${({ theme }) => theme.breakpoints.only('xs')} {
			padding-right: 0;
		}
	}

	.list-item-text {
		font-family: Gilroy, impact, sans-serif;
		text-transform: capitalize;
	}

	.active {
		background-color: ${({ theme }) => theme.palette.action.selected} !important;
		color: ${({ theme }) => theme.palette.primary.main};

		* {
			font-weight: bolder;
		}
	}
`;
