import React, { lazy } from 'react';
import type { PreRoute } from '~/models/route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '~/components/Layout';
import EditUser from '~/pages/EditUser';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faArrowsToCircle,
	faEye,
	faEyeSlash,
	faMoon,
	faSun,
	faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import ResponsiveDrawer from '~/components/Layout';
import { stripUnit } from 'polished';
import CategoriesIndividual from '~/pages/categoriesIndividual';
import NoPermissionsPage from '~/pages/no_permissions';
import { LicenseInfo } from '@mui/x-license-pro';
import Relativity from '~/pages/relativity';

const Home = lazy(() => import('../pages/index'));
const Categories = lazy(() => import('../pages/categories'));
const Policies = lazy(() => import('../pages/policies'));
const ReferringDomain = lazy(() => import('../pages/referringDomain'));
const Profile = lazy(() => import('../pages/profile'));
const Users = lazy(() => import('../pages/users'));
const GallagherUsers = lazy(() => import('../pages/gallagher_users'));
const Offline = lazy(() => import('../pages/offline'));
const Private = lazy(() => import('../components/Private'));
const Login = lazy(() => import('../pages/login'));
const Register = lazy(() => import('../pages/register'));
const Logout = lazy(() => import('../pages/logout'));
const ChangeEmail = lazy(() => import('../pages/change_email'));
const VerifyEmail = lazy(() => import('../pages/verify_email'));
const ResetPassword = lazy(() => import('../pages/reset_password'));
const ConfirmPassword = lazy(() => import('../pages/confirm_password'));
const Concierge = lazy(() => import('../pages/concierge'));

library.add(faTrash, faEye, faEyeSlash, faArrowsToCircle, faSun, faMoon);

LicenseInfo.setLicenseKey(
	'a967144c27c3c7290bd32b1808737a76Tz05MTAyNixFPTE3NDc5Mjg0MDYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const routes: PreRoute[] = [
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/no-permissions',
		element: <NoPermissionsPage />,
	},
	{
		path: '/logout',
		element: <Logout />,
	},
	{
		path: '/register',
		element: <Register />,
	},
	{
		path: '/change-email',
		element: <ChangeEmail />,
	},
	{
		path: '/verify-email',
		element: <VerifyEmail />,
	},
	{
		path: '/reset-password',
		element: <ResetPassword />,
	},
	{
		path: '/concierge',
		element: <Concierge />,
	},
	{
		path: '/forgot-password-confirm',
		element: <ConfirmPassword />,
	},
	{
		element: <Private />,
		children: [
			{
				path: '/',
				element: <Layout />,
				children: [
					{
						index: true,
						element: <Home />,
					},
					{
						path: '/offline',
						element: <Offline />,
					},
					{
						path: '/categories/:id',
						element: <CategoriesIndividual />,
					},
					{
						path: '/categories',
						element: <Categories />,
					},
					{
						path: '/categories/product-rates',
						element: <Relativity />,
					},
					{
						path: '/users/:id',
						element: <EditUser />,
					},
					{
						path: '/users',
						element: <Users />,
					},
					{
						path: '/gallagher-users',
						element: <GallagherUsers />,
					},
					{
						path: '/policies',
						element: <Policies />,
					},
					{
						path: '/referring-domains',
						element: <ReferringDomain />,
					},
					{
						path: '/profile',
						element: <Profile />,
					},
				],
			},
		],
	},
];

export const navigation = [
	{
		name: 'Home',
		path: '/',
		icon: <FontAwesomeIcon icon={['fad', 'pie-chart']} />,
	},
];

export default routes;
